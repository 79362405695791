<template>
  <v-data-table :headers="headers" :items="texts" sort-by="name">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Texte</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="900px">
          <v-card>
            <v-card-title>
              <span class="headline">Eintrag bearbeiten</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="editedItem.main_text" label="Wert"></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.additional_text" label="Betreff (Bei E-Mail)"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Abbrechen
              </v-btn>
              <v-btn color="green darken-1" @click="save">
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      Keine Daten
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

export default {
  name: 'Params',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    texts: [],
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name'
      },
      { text: 'Text', value: 'main_text' },
      { text: 'Betreff (E-Mail)', value: 'additional_text' },
      { text: 'Bearbeiten', value: 'actions', sortable: false }
    ],
    editedItem: {
      name: '',
      main_text: '',
      additional_text: ''
    },
    defaultItem: {
      name: '',
      value: '',
      description: ''
    }
  }),

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    axios
      .get(`${BACKEND_URL}/texts`)
      .then(response => {
        if (response.status === 200) {
          this.texts = response.data;
        }
      })
      .catch(error => {});
  },

  methods: {
    editItem(item) {
      // TODO SEND TO SERVER
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    save() {
      // TODO SEND TO SERVER AND REFRESH VALUES
      axios
        .post(`${BACKEND_URL}/text`, { ...this.editedItem })
        .then(response => {
          if (response.status === 200) {
            this.texts = response.data;
          }
        })
        .catch(error => {});
      this.close();
    }
  }
};
</script>

<style scoped></style>
